/* You can add global styles to this file, and also import other style files */
@use "@fontsource/roboto";
@use "@fontsource/material-icons";
@use "@fontsource-variable/open-sans";
@use '@fontsource-variable/oswald';

html, body { height: 100%; }
body {
	display: flex;

	margin: 0;

	background: var(--quedia-bg-color);
	color: var(--quedia-text-color);

	font-family: Roboto, "Helvetica Neue", sans-serif;

	> app-root { flex: 1 1 auto; }

	--quedia-bg-color: #161319;
	--quedia-bg-color-l1: #170710;
	--quedia-bg-color-l1-burnt: #171215;
	--quedia-bg-color-l1-gray: #313131;
	--quedia-bg-color-l2: #4d0d31;


	--quedia-text-color: white;
	--quedia-text-color-grayed: #6f6f6f;

	--quedia-text-color-prim: #ed2553;
	--quedia-text-color-prim-grayed: #6c465c;

	--quedia-border-color: #372b32;

	/*--quedia-text-color: #fcfcfc;
	--quedia-text-grayed-color: #d9d9d9;

	--quedia-bg-dash-color: #1e232b;
	--quedia-bg-dash-button-color: #3d4655;

	--quedia-border-color: #373737;
	--quedia-border-on-dash-color: #5b5b5b;
	*/

}


button {
	display: inline-block;

	background: none;
	padding: 0;
	border: none;
	border-radius: 0;

	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	text-decoration: none;

	cursor: pointer;
	user-select: none;
	color: var(--quedia-text-color);
}

.material-icons {
	display: inline flex;
	justify-content: center;
	align-items: center;

	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 1em;
	//display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for IE. */
	font-feature-settings: 'liga';
}












/* You can add global styles to this file, and also import other style files */
body.night-mode { filter: invert(1); img { filter: invert(1); } }

*:focus { // Mostly for links
	outline: none; }
*::-moz-focus-inner { // Mostly For buttons
	border: 0; }
p { margin: 0; }

input[type="text"], input[type="username"], input[type="password"] {
	border-radius: 2px;
	background: white;
	text-decoration: none;
	vertical-align: middle;
	border: 1px solid;
	padding: 3px 5px;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
	-moz-appearance:textfield; /* Firefox */
}

input[type=file].hidden, form.hidden { pointer-events: none; opacity: 0; position: absolute; width: 0; height: 0; }


.json-pprint {
	.string { color: green; }
	.number { color: darkorange; }
	.boolean { color: blue; }
	.null { color: magenta; }
	.key { color: #00139f; }
}

@keyframes rotating {
	0%   { transform: rotate(0deg);   }
	100% { transform: rotate(359deg); }
}
@keyframes hue_rotating {
	0%   { filter: hue-rotate(0deg);   }
	100% { filter: hue-rotate(359deg); }
}

@keyframes squashH {
	0%   { transform: scaleX(1); }
	50%  { transform: scaleX(0); }
	100% { transform: scaleX(1); }
}



//Since cdkDrag was made by idiots
//style Belongs to series:
.cdk-drag-preview.episode-ordering {
	margin-left: 25px;
	padding: 3px 15px;
	background: white;
	border-radius: 3px;
	box-shadow: 0 0 1px 0px;

	> span { font-family: "Open Sans Condensed"; }
}
